import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('main', [_c('article', [_c('a', {
    staticClass: "text-h1",
    attrs: {
      "href": "https://www.figma.com/file/3RIWqG3zhPqBoqpwcTbLgM/SendWOW__Component?type=design&node-id=3227-18155"
    }
  }, [_vm._v("Select")]), _vm._v(" "), _c('section', [_c('header', [_vm._v("default")]), _vm._v(" "), _c('Input', {
    attrs: {
      "label": "ラベル",
      "placeholder": "入力",
      "type": "select",
      "items": ['1', '2', '3']
    }
  })], 1), _vm._v(" "), _c('section', [_c('header', [_vm._v("small")]), _vm._v(" "), _c('Input', {
    attrs: {
      "label": "ラベル",
      "placeholder": "入力",
      "type": "select",
      "items": ['1', '2', '3'],
      "dense": ""
    }
  })], 1), _vm._v(" "), _c('section', [_c('header', [_vm._v("multiple")]), _vm._v(" "), _c('Input', {
    attrs: {
      "label": "ラベル",
      "placeholder": "入力",
      "type": "select",
      "items": ['1', '2', '3'],
      "multiple": ""
    }
  })], 1), _vm._v(" "), _c('section', [_c('header', [_vm._v("prepend-item")]), _vm._v(" "), _c('Input', {
    attrs: {
      "label": "ラベル",
      "placeholder": "入力",
      "type": "select",
      "items": ['1', '2', '3']
    },
    scopedSlots: _vm._u([{
      key: "prepend-item",
      fn: function fn() {
        return [_c(VListItem, {
          attrs: {
            "ripple": ""
          }
        }, [_c(VListItemAction, [_c(VIcon, [_vm._v("mdi-plus-circle")])], 1), _vm._v(" "), _c(VListItemContent, [_c(VListItemTitle, [_vm._v("会社を追加する")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _vm._v(" "), _c('section', [_c('header', [_vm._v("button")]), _vm._v(" "), _c('Input', {
    attrs: {
      "label": "ラベル",
      "placeholder": "入力",
      "type": "select",
      "items": ['1', '2', '3']
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c(VDivider, {
          staticClass: "mx-2",
          attrs: {
            "vertical": ""
          }
        }), _vm._v(" "), _c('TextButton', {
          staticClass: "mr-1",
          attrs: {
            "value": "編集",
            "icon": "mdi-pencil-outline"
          },
          on: {
            "mouseup": function mouseup($event) {
              $event.stopPropagation();
            }
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };