/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./Texts.vue?vue&type=template&id=df22194e&scoped=true"
var script = {}
import style0 from "./Texts.vue?vue&type=style&index=0&id=df22194e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df22194e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmallLabel: require('/code/src/components/text/SmallLabel.vue').default,LabelText: require('/code/src/components/text/LabelText.vue').default,TextButton: require('/code/src/components/button/TextButton.vue').default,FrameText: require('/code/src/components/text/FrameText.vue').default})
