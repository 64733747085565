/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./Inputs.vue?vue&type=template&id=85a6169c&scoped=true"
var script = {}
import style0 from "./Inputs.vue?vue&type=style&index=0&id=85a6169c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85a6169c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/code/src/components/input/Input.vue').default,TextButton: require('/code/src/components/button/TextButton.vue').default})
