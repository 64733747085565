import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('main', [_c('article', [_c('a', {
    staticClass: "text-h1",
    attrs: {
      "href": "https://www.figma.com/file/3RIWqG3zhPqBoqpwcTbLgM/SendWOW__Component?type=design&node-id=2924-11970"
    }
  }, [_vm._v("Text")]), _vm._v(" "), _c('section', [_c('header', [_vm._v("status")]), _vm._v(" "), _c('div', [_c('ShippingStatusTag', {
    attrs: {
      "status": 0,
      "text": "オーダー済み"
    }
  })], 1), _vm._v(" "), _c('div', [_c('ShippingStatusTag', {
    attrs: {
      "status": 1,
      "text": "送付先住所入力代行中"
    }
  })], 1), _vm._v(" "), _c('div', [_c('ShippingStatusTag', {
    attrs: {
      "status": 2,
      "text": "オーダーキャンセル"
    }
  })], 1), _vm._v(" "), _c('div', [_c('ShippingStatusTag', {
    attrs: {
      "status": 3,
      "text": "送付準備中"
    }
  })], 1), _vm._v(" "), _c('div', [_c('ShippingStatusTag', {
    attrs: {
      "status": 4,
      "text": "送付キャンセル"
    }
  })], 1), _vm._v(" "), _c('div', [_c('ShippingStatusTag', {
    attrs: {
      "status": 6,
      "text": "発送済み"
    }
  })], 1), _vm._v(" "), _c('div', [_c('ShippingStatusTag', {
    attrs: {
      "status": 7,
      "text": "到着済み"
    }
  })], 1), _vm._v(" "), _c('div', [_c('ShippingStatusTag', {
    attrs: {
      "status": 8,
      "text": "還付"
    }
  })], 1), _vm._v(" "), _c('div', [_c('ShippingStatusTag', {
    attrs: {
      "status": 9,
      "text": "WOWリンク発行済み"
    }
  })], 1), _vm._v(" "), _c('div', [_c('ShippingStatusTag', {
    attrs: {
      "status": 10,
      "text": "差し戻し"
    }
  })], 1), _vm._v(" "), _c('div', [_c('ShippingStatusTag', {
    attrs: {
      "status": 11,
      "text": "下書き"
    }
  })], 1), _vm._v(" "), _c('div', [_c('ShippingStatusTag', {
    attrs: {
      "status": 12,
      "text": "承認待ち"
    }
  })], 1)]), _vm._v(" "), _c('section', [_c('header', [_vm._v("tag")]), _vm._v(" "), _c('div', {
    staticStyle: {
      "width": "400px"
    }
  }, _vm._l(10, function (index) {
    return _c(VChip, {
      key: "tag-".concat(index),
      staticClass: "my-1 mr-2",
      attrs: {
        "outlined": "",
        "small": "",
        "close": "",
        "close-icon": "mdi-close"
      }
    }, [_c('span', {
      directives: [{
        name: "ellipsis",
        rawName: "v-ellipsis"
      }],
      staticStyle: {
        "max-width": "160px"
      }
    }, [_vm._v("ほげほげ")])]);
  }), 1)]), _vm._v(" "), _c('section', [_c('header', [_vm._v("required")]), _vm._v(" "), _c('RequiredIcon'), _vm._v(" "), _c('BetaIcon')], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };