import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('main', [_c('article', [_c('a', {
    staticClass: "text-h1",
    attrs: {
      "href": "https://www.figma.com/file/3RIWqG3zhPqBoqpwcTbLgM/SendWOW__Component?type=design&node-id=1868-2132"
    }
  }, [_vm._v("Button")]), _vm._v(" "), _c('section', [_c('header', [_vm._v("Primay (tailwind)")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("ボタン")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "type": "button"
    }
  }, [_c('mdi-plus', {
    staticClass: "tw-size-6"
  }), _vm._v("ボタン")], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "type": "button",
      "disabled": ""
    }
  }, [_c('mdi-plus', {
    staticClass: "tw-size-6"
  }), _vm._v("ボタン")], 1)]), _vm._v(" "), _c('section', [_c('header', [_vm._v("Primay Small (tailwind)")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary tw-btn-small",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("ボタン")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary tw-btn-small",
    attrs: {
      "type": "button"
    }
  }, [_c('mdi-plus', {
    staticClass: "tw-size-5"
  }), _vm._v("ボタン")], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary tw-btn-small",
    attrs: {
      "type": "button",
      "disabled": ""
    }
  }, [_c('mdi-plus', {
    staticClass: "tw-size-5"
  }), _vm._v("ボタン")], 1)]), _vm._v(" "), _c('section', [_c('header', [_vm._v("Outline (tailwind)")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-primary",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("ボタン")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-primary",
    attrs: {
      "type": "button"
    }
  }, [_c('mdi-plus', {
    staticClass: "tw-size-6"
  }), _vm._v("ボタン")], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-primary",
    attrs: {
      "type": "button",
      "disabled": ""
    }
  }, [_c('mdi-plus', {
    staticClass: "tw-size-6"
  }), _vm._v("ボタン")], 1)]), _vm._v(" "), _c('section', [_c('header', [_vm._v("Outline Small (tailwind)")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-primary tw-btn-small",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("ボタン")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-primary tw-btn-small",
    attrs: {
      "type": "button"
    }
  }, [_c('mdi-plus', {
    staticClass: "tw-size-5"
  }), _vm._v("ボタン")], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-primary tw-btn-small",
    attrs: {
      "type": "button",
      "disabled": ""
    }
  }, [_c('mdi-plus', {
    staticClass: "tw-size-5"
  }), _vm._v("ボタン")], 1)]), _vm._v(" "), _vm._m(0)]), _vm._v(" "), _c('article', [_c('a', {
    staticClass: "text-h1",
    attrs: {
      "href": "https://www.figma.com/file/3RIWqG3zhPqBoqpwcTbLgM/SendWOW__Component?type=design&node-id=1976-2012"
    }
  }, [_vm._v("Text Button")]), _vm._v(" "), _vm._m(1), _vm._v(" "), _vm._m(2), _vm._v(" "), _c('section', [_c('header', [_vm._v("Icon (tailwind)")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-text-primary",
    attrs: {
      "type": "button"
    }
  }, [_c('mdi-pencil-outline', {
    staticClass: "tw-size-6"
  }), _vm._v("チーム編集")], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-text-primary",
    attrs: {
      "type": "button",
      "disabled": ""
    }
  }, [_c('mdi-pencil-outline', {
    staticClass: "tw-size-6"
  }), _vm._v("チーム編集")], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-text-error",
    attrs: {
      "type": "button"
    }
  }, [_c('mdi-close', {
    staticClass: "tw-size-6"
  }), _vm._v("一括キャンセルする")], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-text-error",
    attrs: {
      "type": "button",
      "disabled": ""
    }
  }, [_c('mdi-close', {
    staticClass: "tw-size-6"
  }), _vm._v("一括キャンセルする")], 1)]), _vm._v(" "), _c('section', [_c('header', [_vm._v("外部リンク (tailwind)")]), _vm._v(" "), _c('TwLinkText', {
    staticClass: "tw-text-body-1",
    attrs: {
      "to": "http://example.com",
      "text": "tw-text-body-1",
      "external": ""
    }
  }), _vm._v(" "), _c('TwLinkText', {
    staticClass: "tw-text-body-2",
    attrs: {
      "to": "http://example.com",
      "text": "tw-text-body-2",
      "external": ""
    }
  })], 1), _vm._v(" "), _c('section', [_c('header', [_vm._v("checkbox (tailwind)")]), _vm._v(" "), _c('TwCheckbox', {
    attrs: {
      "value": _setup.check1
    },
    on: {
      "update:value": function updateValue($event) {
        _setup.check1 = $event;
      }
    }
  }, [_vm._v("チェックボックス " + _vm._s(_setup.check1))]), _vm._v(" "), _c('TwCheckbox', {
    attrs: {
      "value": _setup.check2
    },
    on: {
      "update:value": function updateValue($event) {
        _setup.check2 = $event;
      }
    }
  }, [_vm._v("チェックボックス " + _vm._s(_setup.check2))]), _vm._v(" "), _c('TwCheckbox', {
    attrs: {
      "value": false,
      "disabled": ""
    }
  }, [_vm._v("チェックボックス")]), _vm._v(" "), _c('TwCheckbox', {
    attrs: {
      "value": true,
      "disabled": ""
    }
  }, [_vm._v("チェックボックス")]), _vm._v(" "), _c('TwCheckbox', {
    staticClass: "!tw-text-primary",
    attrs: {
      "circle": ""
    }
  }, [_vm._v("チェックボックス")]), _vm._v(" "), _c('TwCheckbox', {
    attrs: {
      "cushion": ""
    }
  }, [_vm._v("チェックボックス")]), _vm._v(" "), _c('TwCheckbox', {
    attrs: {
      "circle": "",
      "cushion": ""
    }
  }, [_vm._v("チェックボックス")])], 1), _vm._v(" "), _c('section', [_c('header', [_vm._v("radiobutton (tailwind)")]), _vm._v(" "), _c('TwRadio', {
    attrs: {
      "current": _setup.radio,
      "value": "1",
      "name": "test"
    },
    on: {
      "update:current": function updateCurrent($event) {
        _setup.radio = $event;
      }
    }
  }, [_vm._v("ラジオボタン " + _vm._s(_setup.radio == '1'))]), _vm._v(" "), _c('TwRadio', {
    attrs: {
      "current": _setup.radio,
      "value": "2",
      "name": "test"
    },
    on: {
      "update:current": function updateCurrent($event) {
        _setup.radio = $event;
      }
    }
  }, [_vm._v("ラジオボタン " + _vm._s(_setup.radio == '2'))]), _vm._v(" "), _c('TwRadio', {
    attrs: {
      "current": _setup.radio,
      "value": "3",
      "name": "test",
      "disabled": ""
    },
    on: {
      "update:current": function updateCurrent($event) {
        _setup.radio = $event;
      }
    }
  }, [_vm._v("ラジオボタン")]), _vm._v(" "), _c('TwRadio', {
    attrs: {
      "current": "4",
      "value": "4",
      "name": "disabled",
      "disabled": ""
    }
  }, [_vm._v("ラジオボタン")])], 1), _vm._v(" "), _c('section', [_c('header', [_vm._v("タグ追加")]), _vm._v(" "), _c('div', {
    staticClass: "d-flex"
  }, [_c(VChip, {
    key: "add",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_c(VIcon, {
    attrs: {
      "small": "",
      "left": "",
      "color": "primary"
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" "), _c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v("タグ追加")])], 1)], 1)]), _vm._v(" "), _c('section', [_c('header', [_vm._v("追加ボタン (tailwind)")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-dotted tw-w-full tw-h-full",
    attrs: {
      "type": "button"
    }
  }, [_c('mdi-account-plus-outline', {
    staticClass: "tw-mr-2 tw-size-6 tw-shrink-0"
  }), _vm._v("\n        配送先を追加する\n      ")], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-dotted tw-w-full tw-h-full tw-min-h-[105px]",
    attrs: {
      "type": "button"
    }
  }, [_c('mdi-plus-circle', {
    staticClass: "tw-mr-2 tw-size-6 tw-shrink-0"
  }), _vm._v("\n        新しい住所を追加する\n      ")], 1)]), _vm._v(" "), _c('section', [_c('header', [_vm._v("fileupload (tailwind)")]), _vm._v(" "), _c(_setup.ContactCsvLoader)], 1), _vm._v(" "), _c('section', [_c('header', [_vm._v("SNSログイン")]), _vm._v(" "), _c(VBtn, {
    staticStyle: {
      "border-color": "var(--v-color-border-base)",
      "border-width": "1px"
    },
    attrs: {
      "rounded": "",
      "outlined": "",
      "color": "primary-text"
    }
  }, [_c('ImagesGoogle', {
    staticClass: "mr-2"
  }), _vm._v("\n        " + _vm._s(_vm.$t('loginWithGoogle')) + "\n      ")], 1), _vm._v(" "), _c(VBtn, {
    staticStyle: {
      "border-color": "var(--v-color-border-base)",
      "border-width": "1px"
    },
    attrs: {
      "rounded": "",
      "outlined": "",
      "color": "primary-text",
      "disabled": ""
    }
  }, [_c('ImagesGoogle', {
    staticClass: "mr-2"
  }), _vm._v("\n        " + _vm._s(_vm.$t('loginWithGoogle')) + "\n      ")], 1)], 1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('section', [_c('header', [_vm._v("Error (tailwind)")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-error",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("削除")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-error tw-btn-small",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("削除")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-error tw-btn-small",
    attrs: {
      "type": "button",
      "disabled": ""
    }
  }, [_vm._v("削除")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('section', [_c('header', [_vm._v("Primay (tailwind)")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-text-primary",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("ボタン")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-text-primary",
    attrs: {
      "type": "button",
      "disabled": ""
    }
  }, [_vm._v("ボタン")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('section', [_c('header', [_vm._v("Error (tailwind)")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-text-error",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("ボタン")]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-text-error",
    attrs: {
      "type": "button",
      "disabled": ""
    }
  }, [_vm._v("ボタン")])]);
}];
export { render, staticRenderFns };