import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('main', [_c('article', [_c('a', {
    staticClass: "text-h1",
    attrs: {
      "href": "https://www.figma.com/file/3RIWqG3zhPqBoqpwcTbLgM/SendWOW__Component?type=design&node-id=2990-6849"
    }
  }, [_vm._v("Tab")]), _vm._v(" "), _c('section', [_c('header', [_vm._v("tab")]), _vm._v(" "), _c(VTabs, [_c(VTab, [_vm._v("タブテキスト")]), _vm._v(" "), _c(VTab, [_vm._v("タブテキスト")]), _vm._v(" "), _c(VTab, [_vm._v("タブテキスト")])], 1)], 1)]), _vm._v(" "), _vm._m(0)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('article', [_c('a', {
    staticClass: "text-h1",
    attrs: {
      "href": "https://www.figma.com/file/3RIWqG3zhPqBoqpwcTbLgM/SendWOW__Component?type=design&node-id=2990-6848"
    }
  }, [_vm._v("Scrollbar")]), _vm._v(" "), _c('section', [_c('header', [_vm._v("scrollbar")]), _vm._v(" "), _c('div', {
    staticStyle: {
      "width": "300px",
      "height": "50px",
      "overflow-x": "scroll"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "600px"
    }
  })])])]);
}];
export { render, staticRenderFns };