import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('main', [_c('article', [_c('a', {
    staticClass: "text-h1",
    attrs: {
      "href": "https://www.figma.com/file/3RIWqG3zhPqBoqpwcTbLgM/SendWOW__Component?type=design&node-id=1868-2132"
    }
  }, [_vm._v("Input")]), _vm._v(" "), _c('section', [_c('header', [_vm._v("default")]), _vm._v(" "), _c('Input', {
    attrs: {
      "label": "ラベル",
      "placeholder": "入力"
    }
  })], 1), _vm._v(" "), _c('section', [_c('header', [_vm._v("multiline")]), _vm._v(" "), _c('Input', {
    attrs: {
      "label": "ラベル",
      "placeholder": "入力",
      "type": "multiLine"
    }
  })], 1), _vm._v(" "), _c('section', [_c('header', [_vm._v("icon")]), _vm._v(" "), _c('Input', {
    attrs: {
      "label": "ラベル",
      "placeholder": "入力",
      "prepend-inner-icon": "mdi-calendar"
    }
  })], 1), _vm._v(" "), _c('section', [_c('header', [_vm._v("link")]), _vm._v(" "), _c('Input', {
    attrs: {
      "label": "ラベル",
      "placeholder": "入力"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c(VDivider, {
          staticClass: "mx-2",
          attrs: {
            "vertical": ""
          }
        }), _vm._v(" "), _c('TextButton', {
          staticClass: "mr-1",
          attrs: {
            "value": "コピー",
            "icon": "mdi-content-copy"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _vm._v(" "), _c('section', [_c('header', [_vm._v("small default")]), _vm._v(" "), _c('Input', {
    attrs: {
      "label": "ラベル",
      "placeholder": "入力",
      "dense": ""
    }
  })], 1), _vm._v(" "), _c('section', [_c('header', [_vm._v("small icon")]), _vm._v(" "), _c('Input', {
    attrs: {
      "label": "ラベル",
      "placeholder": "入力",
      "dense": "",
      "prepend-inner-icon": "mdi-calendar"
    }
  })], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };