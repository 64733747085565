import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('main', [_vm._m(0), _vm._v(" "), _c('article', [_c('a', {
    staticClass: "text-h1",
    attrs: {
      "href": "https://www.figma.com/file/3RIWqG3zhPqBoqpwcTbLgM/SendWOW__Component?type=design&node-id=2924-11967"
    }
  }, [_vm._v("Label")]), _vm._v(" "), _c('section', [_c('header', [_vm._v("label")]), _vm._v(" "), _c('SmallLabel', {
    attrs: {
      "label": "ラベル"
    }
  })], 1)]), _vm._v(" "), _c('article', [_c('a', {
    staticClass: "text-h1",
    attrs: {
      "href": "https://www.figma.com/file/3RIWqG3zhPqBoqpwcTbLgM/SendWOW__Component?type=design&node-id=2924-11970"
    }
  }, [_vm._v("Text")]), _vm._v(" "), _c('section', [_c('header', [_vm._v("text")]), _vm._v(" "), _c('div', {
    staticClass: "d-flex flex-row"
  }, [_c('LabelText', {
    attrs: {
      "label": "ラベル",
      "text": "山田 太郎"
    }
  }), _vm._v(" "), _c('LabelText', {
    attrs: {
      "label": "ラベル",
      "text": "山田 太郎",
      "x-small": ""
    }
  })], 1), _vm._v(" "), _c('LabelText', {
    attrs: {
      "label": "ラベル",
      "text": "山田 太郎",
      "horizontal": ""
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "d-flex flex-row"
  }, [_c('LabelText', {
    attrs: {
      "label": "ラベル",
      "text": "山田 太郎"
    },
    scopedSlots: _vm._u([{
      key: "label.append",
      fn: function fn() {
        return [_c('TextButton', {
          staticClass: "ml-1",
          attrs: {
            "value": "\u5909\u66F4"
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c('LabelText', {
    attrs: {
      "label": "ラベル",
      "text": "山田 太郎"
    },
    scopedSlots: _vm._u([{
      key: "label.append",
      fn: function fn() {
        return [_c('TextButton', {
          staticClass: "ml-1",
          attrs: {
            "value": "\u524A\u9664",
            "color": "error"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "d-flex flex-row"
  }, [_c('LabelText', {
    attrs: {
      "label": "ラベル",
      "text": "山田 太郎",
      "link": "https://example.com"
    }
  }), _vm._v(" "), _c('LabelText', {
    attrs: {
      "label": "ラベル",
      "text": "山田 太郎",
      "x-small": "",
      "link": "https://example.com"
    }
  })], 1)], 1), _vm._v(" "), _c('section', [_c('header', [_vm._v("status")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-2 d-flex align-center disabled-text--text"
  }, [_c(VIcon, {
    staticClass: "mr-1",
    attrs: {
      "size": "24",
      "color": "disabled-text"
    }
  }, [_vm._v("\n          mdi-check-circle\n        ")]), _vm._v("\n        " + _vm._s(_vm.$t('orders.id.qrcodeNotRead')) + "\n      ")], 1)]), _vm._v(" "), _c('section', [_c('header', [_vm._v("frame-text")]), _vm._v(" "), _c('FrameText', {
    staticStyle: {
      "max-width": "300px"
    },
    attrs: {
      "value": "テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。"
    }
  }), _vm._v(" "), _c('FrameText', {
    staticStyle: {
      "max-width": "300px"
    },
    attrs: {
      "value": "テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。",
      "x-small": ""
    }
  })], 1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('article', [_c('a', {
    staticClass: "text-h1",
    attrs: {
      "href": "https://www.figma.com/file/3RIWqG3zhPqBoqpwcTbLgM/SendWOW__Component?type=design&node-id=3246-35353"
    }
  }, [_vm._v("Heading")]), _vm._v(" "), _c('section', [_c('header', [_vm._v("heading")]), _vm._v(" "), _c('h5', [_vm._v("heading text")]), _vm._v(" "), _c('h6', [_vm._v("heading text")]), _vm._v(" "), _c('span', {
    staticClass: "text-subtitle-1"
  }, [_vm._v("heading text")])])]);
}];
export { render, staticRenderFns };