/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./Tags.vue?vue&type=template&id=374c285b&scoped=true"
var script = {}
import style0 from "./Tags.vue?vue&type=style&index=0&id=374c285b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "374c285b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShippingStatusTag: require('/code/src/components/widgets/ShippingStatusTag.vue').default,RequiredIcon: require('/code/src/components/icons/RequiredIcon.vue').default,BetaIcon: require('/code/src/components/icons/BetaIcon.vue').default})
