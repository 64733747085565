var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "tw-flex tw-w-dvw tw-h-dvh tw-overflow-x-scroll tw-bg-surface"
  }, [_c('div', {
    staticClass: "tw-w-10 tw-fixed tw-z-50 tw-bottom-10 tw-right-10 tw-py-5 tw-px-2 tw-bg-on-surface tw-rounded-full tw-cursor-pointer tw-text-surface",
    on: {
      "click": function click($event) {
        _vm.$nuxt.$vuetify.theme.dark = !_vm.$nuxt.$vuetify.theme.dark;
      }
    }
  }), _vm._v(" "), _c(_setup.ButtonWidgets), _vm._v(" "), _c(_setup.TextWidgets), _vm._v(" "), _c(_setup.TagWidgets), _vm._v(" "), _c(_setup.TabWidgets), _vm._v(" "), _c(_setup.InputWidgets), _vm._v(" "), _c(_setup.SelectWidgets)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };